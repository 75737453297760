import ReactGA from 'react-ga4';
import Header from './Header';

// Initialize GA with your measurement ID
ReactGA.initialize('G-ZFE5X0R3SZ');

// Send a pageview to Google Analytics
ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

export default Header;
